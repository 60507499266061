<template>
  <div class="talentsData" v-loading="loading" element-loading-text="正在上传，请稍后">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <!-- <el-button @click="addItem" type="primary" icon="el-icon-plus"
            >添加</el-button
          >-->
          <import-data @fileClick="fileClick" @fileClicks="fileClicks" moduleName="行业知识" />
          <el-button @click="multDelete" style="margin: 0px">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">按上传时间</div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.order == 1 ? 'iconColor' : 'isActiveTime1',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.order == 0 ? 'iconColor' : 'isActiveTime1',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据名称、别称、关键词进行全文搜索"
          v-model="queryInfo.queryConditions"
          style="width:470px"
          @change="getSchemes1"
        >
          <template slot="append">
            <div @click="getSchemes1" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="序号" align="center" width="90">
          <template v-slot="scope">
            <span>
              {{
              (queryInfo.pageNum - 1) * queryInfo.pageSize +
              (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="largeCategory" label="行业类别" width="250">
          <template v-slot="{ row }">
            <div @click="editTalent(row)" style="cursor:pointer;">
              <span>{{row.largeCategory}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="largeExplain" label="类别说明" width="450">
          <template slot-scope="scope">
            <div @click="editTalent(scope.row)" style="cursor: pointer">
              <el-popover
                placement="right"
                width="400"
                trigger="hover"
                :content="scope.row.largeExplain"
              >
                <div slot="reference">
                  <div v-if="(scope.row.largeExplain !== null) && (scope.row.largeExplain !== '')">
                    {{scope.row.largeExplain.substring(0,100)}}
                    <span
                      v-if="scope.row.largeExplain.length>100"
                    >...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="industryLevel" label="行业层级"></el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="createTime" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column prop="createName" label="创建人"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[10, 30, 50,100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from '@/components/DicTagButton.vue'
import UploadFileTalent from '@/components/UploadFileTalent.vue'
import ButtonTable from '../../components/ButtonTable.vue'
import { getIndustry, deleteIndustry } from '@/api/industry.js'
import DicRadioButton from '@/components/DicRadioButton.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import ImportData from '@/components/ImportData.vue'
import {
  getKnowTechList,
  getIndustryList,
  getKnowFieldList,
} from '@/api/common'
export default {
  components: {
    ButtonTable,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    UploadFileTalent,
    ImportData,
  },
  created() {
    this.getSchemes()
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
      },
      loading: false,
      dataData: [],
      total: 0,
      // 搜索
      input2: '',
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
    }
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      if (this.queryInfo.order == 1) {
        this.queryInfo.order = 0
        this.getSchemes()
      } else {
        this.queryInfo.order = 1
        this.getSchemes()
      }
    },
    // 浏览人才数据
    getSchemes1() {
      let queryConditions = this.queryInfo.queryConditions
      this.queryInfo = {
        queryConditions: queryConditions,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      }
      this.getSchemes()
    },
    async getSchemes() {
      const res = await getIndustry(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSchemes()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSchemes()
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id
      })
    },
    // 跳转编辑页面
    editTalent(data) {
      // this.$router.push({
      //   name: 'industryInfo',
      //   params: {
      //     personData: data,
      //     personType: 1,
      //   },
      // })
      let text = this.$router.resolve({
        path: "/knowledgeData/industry/industryInfo",
        query: {
          personData: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 单个删除
    async deleteTalent(id) {
      this.deleteTalentData[0] = id
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteIndustry(this.deleteTalentData)
          if (res.code == 200) {
            this.getSchemes()
            this.deleteTalentData = []
            this.$message.success('删除成功')

            const res1 = await getIndustryList()
            res1.data = JSON.parse(
              JSON.stringify(res1.data).replace(/industryName/g, 'codeName')
            )
            sessionStorage.setItem('industry', JSON.stringify(res1.data))
          }
        }
      )
    },
    // 批量删除
    async multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm('此操作将永久删除批量文件, 是否继续?', '提示').then(
          async () => {
            const res = await deleteIndustry(this.multipleSelection)
            if (res.code == 200) {
              this.getSchemes()
              this.multipleSelection = []
              this.$message.success('删除成功')

              const res1 = await getIndustryList()
              res1.data = JSON.parse(
                JSON.stringify(res1.data).replace(/industryName/g, 'codeName')
              )
              sessionStorage.setItem('industry', JSON.stringify(res1.data))
            }
          }
        )
      } else {
        this.$message.error('批量删除必须大于一条')
      }
    },
    handleClose() {
      this.$refs.checkboxEnterprise.clear()
    },
    addItem() {
      this.$router.push({
        name: 'industryInfo',
        params: {
          personCode: '',
        },
      })
    },
    fileClick() {
      this.loading = false
      this.getSchemes()
    },
    fileClicks() {
      this.loading = true
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 1
      this.getSchemes()
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 0
      this.getSchemes()
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    async btnClick(data) {
      const res = await editFields(data)
      this.$message.success('修改成功')
      this.getTechnologys()
    },
    formatTime(row, column) {
        const date = new Date(row[column.property])
        return date.getFullYear() + '-' +
         (date.getMonth()+1)+ '-' +
         date.getDate()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  .upload_time {
    position: relative;
    color: #1f212b;
    font-size: 14px;
    margin-left: 20px;
    .el-icon-caret-top,
    .el-icon-caret-bottom {
      position: absolute;
      right: -16px;
      color: #1f212b;
    }
    .el-icon-caret-top {
      top: 0px;
    }
    .el-icon-caret-bottom {
      top: 6px;
    }
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.caretTime {
  cursor: pointer;
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
</style>
